import React from "react"
import { Helmet } from "react-helmet"
import TemplatePage from "../../components/TemplatePage"
import TabelActe from "../../components/legislatie/TabelActe"
import { getAllConditiiStandard } from "../../utils/api/conditiiStandard"
import { ActNormativ } from "../../utils/api/acteNormative"
export default function ConditiiStandard({ location }) {
  const [items, setItems] = React.useState<ActNormativ[]>([])

  React.useEffect(() => {
    const getItems = async () => {
      try {
        const apiResponse = await getAllConditiiStandard()
        if (apiResponse.type) setItems(apiResponse.data)
      } catch (error) {
        console.log(error)
      }
    }
    getItems()
  }, [])

  return (
    <TemplatePage
      location={location}
      title="Condiții standard"
      additionalClassName="templateFullContainerFaqScreen"
    >
      <Helmet>
        <title>Condiții Standard - Gazmir</title>
        <meta
          name="description"
          content="Citeste Conditii Standard Gazmir pentru a fi la curent cu modul de desfasurare a serviciilor Gazmir. "
        />
        <meta
          name="keywords"
          content="Conditii standard furnizare gazmir,gazmir conditii standard furnizare,Conditii contract gazmir,conditii generale furnizare gazmir"
        />
      </Helmet>
      <TabelActe items={items} />
    </TemplatePage>
  )
}
